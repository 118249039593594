var QuickSummary = {
  init: function() {
    this.assign_event_handlers()

  },
  assign_event_handlers: function() {
    $(document).on('click', '#show-summary', function() {
      let form = $('#quick-summaries-form')

      $('#summary_region').val($('#report_region').val())
      $('#summary_start_date').val($('#report_start_date').val())
      $('#summary_end_date').val($('#report_end_date').val())

      form.submit()
    })
  }
}

document.addEventListener("turbolinks:load", () => {
  if ($('#quick-summaries-form')[0]) {
    QuickSummary.init();
  }
});
