var InstructorPayment = {
  init: function() {
    this.assign_event_handlers()
  },
  assign_event_handlers: function() {
    $(document).on('change', '.eps-item', function() {
      var item_id = ''

      if ($(this).is(':checked')) {
        item_id = $(this).data('id')
        if ($(this).val() == 1) {
          $('#epsv-' + item_id).prop('checked', false)
        } else {
          $('#epsp-' + item_id).prop('checked', false)
        }
      }
    })

    $(document).on('click', '#save-submit-instructor-payments', function() {
      var event_id = ''
      var payment_status = []

      $('.event-item').each(function() {
        event_id = $(this).data('id')
        pay_status = ''

        if ($('#epsp-' + event_id).is(':checked')) {
          pay_status = $('#epsp-' + event_id).val()
        } else if ($('#epsv-' + event_id).is(':checked')) {
          pay_status = $('#epsv-' + event_id).val()
        }

        if (pay_status != '') {
          payment_status.push({ event_id: event_id, payment_status: pay_status })
        }
      })

      if (payment_status.length > 0) {
        InstructorPayment.send_payment_status_updates(payment_status, 'summary')
      } else {
        alert('Update respective events with Paid or Voluteer payment status.')
      }
    })

    $(document).on('click', '#edit-instructor-payments', function(e) {
      var toggle = $(this)
      var event_id = ''
      var payment_status = []
      var instructor_id = $(toggle).data('instructor')

      e.stopImmediatePropagation()
      if ($(toggle).data('mode') == 'edit') {
        $(toggle).html('Save')
        $(toggle).data('mode', 'save')
        $('.epsi-item').each( function() {
          $(this).prop('disabled', false)
        })
      } else {
        $('.epsi-item').each(function() {
          event_id = $(this).data('id')
          pay_status = ''

          if ($('#epsiv-' + event_id).is(':checked')) {
            pay_status = $('#epsiv-' + event_id).val()
          } else {
            pay_status = 1 // default to paid
          }

          if (pay_status != '') {
            payment_status.push({ event_id: event_id, payment_status: pay_status })
          }
        })

        if (payment_status.length > 0) {
          InstructorPayment.send_payment_status_updates(payment_status, instructor_id)
        } else {
          alert('Select events for volunteer status.')
        }
      }
    })
  },
  send_payment_status_updates: function(payment_status, origin) {
    $.ajax({
      type: 'POST',
      url: '/admin/instructor_payments/update_status',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      data: {
        events: {
          status: payment_status,
          origin: origin
        }
      }
    })
  }
}

document.addEventListener("turbolinks:load", () => {
  if ($('.admin-instructor-payments')[0]) {
    InstructorPayment.init();
  }
});
